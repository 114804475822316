import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container, Row, Col } from "shards-react";

import MainNavbar from "../_components/layout/MainNavbar/MainNavbar";
import { MainSidebar } from "../_components/layout/MainSidebar/MainSidebar";

import Spin from "antd/es/spin";
import "../App.css";

const DefaultLayout = ({
  children,
  noNavbar,
  noFooter,
  loadingGlobal,
  showLoading,
  alertShow
}) => {
  return (
    <Spin size="large" spinning={loadingGlobal.loadingGlobal}>
      <Container fluid>
        <Row>
          <a
            href="https://api.whatsapp.com/send?phone=5491154617972"
            target="_blank"
            style={{
              padding: "3px 8px",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "1000",
              fontSize: "35px",
              borderRadius: "4px"
            }}
          >
            <img
              src="https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-2-1.png"
              style={{ width: "100%", maxWidth: "50px" }}
            ></img>
          </a>
          <MainSidebar />
          <Col
            className="main-content p-0 noprintercs"
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm={{ size: 12 }}
            tag="main"
          >
            {!noNavbar && <MainNavbar />}
            <div className="main-content-container container-fluid px-4 noprintercs">
              {children}
            </div>
            <br />
            <footer className="main-footer d-flex p-2 px-3 pt-8 bg-white border-top">
              <span className="copyright ml-auto my-auto mr-2">
                Copyright © 2025 (v02.0.1)
              </span>
            </footer>
          </Col>
        </Row>
      </Container>
    </Spin>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
  showLoading: false
};

//export default DefaultLayout;

function mapState(state) {
  const { loadingGlobal } = state;

  console.log("MAPA", state);
  return { loadingGlobal };
}

const actionCreators = {};

const connectedApp = connect(
  mapState,
  actionCreators
)(DefaultLayout);
export { connectedApp as DefaultLayout };
//export default DefaultLayout;
